import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Accordion from "../components/Accordion"
import AtTheAirport from "../images/static-only/flying-tips/At_the_airport.jpg"
import Comfort from "../images/static-only/flying-tips/comfort-2.jpg"
import FlightTips from "../images/static-only/flying-tips/Flight_tips.jpg"
import KeepingThemEntertained from "../images/static-only/flying-tips/Keeping_them_entertained.jpg"
import PreparingToTravel from "../images/static-only/flying-tips/Preparing_to_travel.jpg"
import PreventingPain from "../images/static-only/flying-tips/Preventing_ear_pain_and_relief.jpg"

/* Accordion Listing structure = Hero > Accordions */

class AccordionListing extends React.Component {
  componentDidMount() {
    // Inactivity()
    Accordion()
    document.body.classList.remove('-nav-active')
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Meet MJN - Tips for Flying</title>
        </Helmet>
        <main role="main" className="content-main -content-accordion-listing">

          <section className="hero-area -general -accordion-listing">
            <img src={FlightTips} alt="An airplane cabin" />

            <div className="hero-area__inner">
              <h1>Tips <strong>for Flying</strong></h1>
              <nav className="breadcrumb" role="navigation">
                  <Link to="/home" title="Home">
                    <i className="fas fa-arrow-left"></i>
                    Home
                  </Link>
              </nav>
            </div>
          </section>

          <section className="accordion-listing">
            <nav className="breadcrumb -accordion -white" role="navigation">
              <Link to="/home" title="Home">
                <i className="fas fa-arrow-left"></i>
                Home
              </Link>
            </nav>

            <div className="accordion">
              <div className="accordion__heading menu-button">Preparing to travel</div>
              <div className="accordion__content">
                <img src={PreparingToTravel} alt="A mother and child packing" />
                <div className="rich-text">
                  <p>Before you travel, letting your children know what to expect can help alleviate any potential distress that arriving to airports can sometimes cause. This can also help to ensure travelling doesn't become a daunting experience, but instead an exciting one that your children look forward to. </p>

                  <h3>Remember to make a checklist</h3>
                  <p>Before packing your carry-on, it is a good idea to make a checklist. Be aware, airport retailers rarely carry many baby supplies, so ensuring you have everything you need packed a couple of days in advance can really help to make the travelling process run much more smoothly and efficiently.</p>
                  <p>Here's a general list of necessities:</p>
                  <ul>
                    <li>At least one change of clothes for you and your baby. You may want to pack more if you're flying long haul.</li>
                    <li>Pack a few blankets – they help provide privacy whilst nursing, help keep your baby warm and also come in useful if your baby happens to spit-up after feeding.</li>
                    <li>Diapers and baby wipes. Take enough for the duration of your travel and plus enough for an extra 24 hours extra in case of delay.</li>
                    <li>If you're breastfeeding, nursing pads and shields can help in the event of mishaps.</li>
                    <li>Ziploc bags are useful for storing snacks and keeping dirty clothes separate in your baby bag</li>
                    <li>Formula and bottles if you bottle-feed. Again, plan for travel time plus 24 hours in the event of delays.</li>
                    <li>Ensure all devices are fully charged with a selection of movies and games downloaded </li>
                    <li>Books and a couple of your baby's favourite comfort toys</li>
                    <li>A sling or a baby carrier</li>
                    <li>Baby-friendly snacks</li>
                  </ul>

                  <h3>Medication</h3>
                  <p>Remember to pack any prescription and other medicine you might require in your baby bag for easy access. Check with the airport on rules around medication and liquid medication, with and without prescription. For ease and efficiency when going through security, keep them in their original packaging, so they are easily identifiable.</p>
                  
                  <h3>Dress in comfortable layers</h3>
                  <p>Think comfort and convenience when travelling, those adorable holiday outfits can wait! Choose clothing that is cozy and easy to change.</p>
                  <p>Layers are a must for everyone when travelling between different climates and can also help with changes in temperature on the plane. If you get delayed on the runway it can often become too warm, but once airborne you may require extra blankets as the temperature drops.</p>

                  <p><em>Disclaimer: The message is for informational purposes only. Parenting advice is given as suggestions only. The information does not replace any advice from doctor. The information is not intended for, nor should it be used as a substitute for, the rendering of medical, nursing, or professional healthcare advice or services or the practice of medicine, in any jurisdiction.</em></p>

                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">At the airport</div>
              <div className="accordion__content">
                <img src={AtTheAirport} alt="A mother and child at an airport lounge" />
                <div className="rich-text">
                  <h3>Going through security</h3>
                  <p>To avoid a delay at security, it is a good idea to check with the airport guidelines around bringing powder through security if you are formula-feeding. You can also purchase single-serving formula packs. That way you only need to add water once past security and once on-board flight attendants will be happy to provide bottled water which can be warmed by placing in a warm mug or bowl of water.</p>

                  <h3>Visit the restroom before your flight</h3>
                  <p>It's always best to board a plane with a clean and dry nappy so be sure to visit the airport bathroom one last time before boarding. You might want to double up on the diaper cream and put your baby into not one, but two nappies for extra leak protection.</p>
                  <p>If your child is potty training, of course you'll want one last potty break, too. A pre-plane bathroom visit means one less trip to the small airplane lavatory. This leads to less work in the air and less inconvenience for those seated around you.</p>

                  <h3>Gate check baby gear</h3>
                  <p>It is a good idea to check if your airline allows you to gate check baby gear including strollers and car seats. Gate checked items will typically be waiting for you in the same spot at your destination when you arrive but sometimes may be retrieved from the baggage claim area.</p>

                  <h3>Use the pre-boarding option</h3>
                  <p>Getting settled on board a flight takes more time with young children. You may think spending as little time as possible on the plane is the more optimal solution, however, flying with a baby or toddler can often be stressful, and pre-boarding can help avoid the sighs and impatience of other passengers as you take the time to situate yourselves.</p>

                  <p><em>Disclaimer: The message is for informational purposes only. Parenting advice is given as suggestions only. The information does not replace any advice from doctor. The information is not intended for, nor should it be used as a substitute for, the rendering of medical, nursing, or professional healthcare advice or services or the practice of medicine, in any jurisdiction.</em></p>

                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">Feeding on board</div>
              <div className="accordion__content">
                <img src={FlightTips} alt="An airplane cabin" />
                <div className="rich-text">
                  <h3>Breastfeeding on planes</h3>
                  <p>Breastfeeding on the plane might be a necessity but you can pump milk and bring it on the plane in bottles if you're nervous about nursing – airport security simply scan the milk with a hand-held device.</p>
                  <p>If you're uncomfortable about nursing next to a stranger, bring two safety pins with you. That way, you can pin a receiving blanket or in-flight blanket(s) to the seat in front of you and on the side(s) of your own seat to create a privacy curtain. </p>

                  <p><em>Disclaimer: The message is for informational purposes only. Parenting advice is given as suggestions only. The information does not replace any advice from doctor. The information is not intended for, nor should it be used as a substitute for, the rendering of medical, nursing, or professional healthcare advice or services or the practice of medicine, in any jurisdiction.</em></p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">Preventing ear pain &amp; relief</div>
              <div className="accordion__content">
                <img src={PreventingPain} alt="A toddler looking out a plane window" />
                <div className="rich-text">
                  <p>If you have any concerns, it is advised that you contact your pediatrician or healthcare provider prior to travelling around the best ways to prevent or relieve common airplane issues such as ear pain.</p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">Comfort &amp; safety on board</div>
              <div className="accordion__content">
                <img src={Comfort} alt="A baby sleeping on their mother's chest" />
                <div className="rich-text">
                  <h3>Keep your baby buckled</h3>
                  <p>If your child is under the age of two years old, you are not required to buy a separate seat if you choose for them to remain seated on your lap for the duration of the flight. If you wish to purchase a seat, they must be secured providing in a FAA (Federal Aviation Administration) car seat. From two years on, it is required that your child must have their own seat. </p>
                  <p>It is advised to check with your airline their guidelines around travelling with infants and purchasing seats.</p>
                  <p>Buckled into a car seat or other approved restraint is the safest place for a baby or toddler on an airplane, especially in case of unexpected turbulence. </p>
                  <p>The time may come when you need to unbuckle your youngster for a nappy change, potty break or to exercise, which is especially important during a long flight. Once your child is unbuckled you may struggle to get them back into the seat, preferring to remain on your lap or walk up and down the aisle if they're a newly walking toddler. Therefore, it's best to stay safely fastened for as long as reasonable.</p>

                  <p><em>Disclaimer: The message is for informational purposes only. Parenting advice is given as suggestions only. The information does not replace any advice from doctor. The information is not intended for, nor should it be used as a substitute for, the rendering of medical, nursing, or professional healthcare advice or services or the practice of medicine, in any jurisdiction.</em></p>

                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">Keep them entertained</div>
              <div className="accordion__content">
                <img src={KeepingThemEntertained} alt="A pot of colouring pencils" />
                <div className="rich-text">
                  <p>Flying with babies and young children can be stressful, but there are steps you can take to keep your little one occupied and entertained ensuring the flight is as pleasant as possible. </p>
                  <p>It is important to remember that the sound in children's ears appears louder due to smaller ear canals, as opposed to an adult with larger ear canals. If you use headphones, ensure your toddler is able to tell you whether the volume is comfortable enough for them.</p>

                  <h3>Impatient passengers</h3>
                  <p>No matter what measures you take, tantrums may still occur on the airplane as your baby or toddler struggles to settle down. Inevitably, this leads to disruption amongst the flight and some passengers becoming noticeably frustrated.</p>
                  <p>It is important that you do best to try and soothe your child and be seen to be doing so by other travelers near you. It is easier said than done but focus on caring for your baby and ignoring those who are insensitive or rude. Your plane will safely land soon enough, and you will be able to get on with enjoying the next part of the journey.</p>
                  <p>Tips for soothing your child on-board:</p>
                  <ul>
                    <li>Request an aisle seat. A little extra shoulder room can make a lot of difference and lessen the discomfort for you and your little one that is often associated with being seated between two people.</li>
                    <li>Walking them up and down the aisle may help if they're unsettled providing, they do not cause too much inconvenience to other passengers.</li>
                    <li>Read stories or play games such as peek-a-boo. You may feel a little self-conscious however a happy, entertained baby is much more pleasant for other passengers than a unhappy and irritable baby.</li>
                    <li>Feeding your baby on take-off and landing will help prevent uncomfortable ear-popping due to them swallowing. </li>
                  </ul>

                  <p><em>Disclaimer: The message is for informational purposes only. Parenting advice is given as suggestions only. The information does not replace any advice from doctor. The information is not intended for, nor should it be used as a substitute for, the rendering of medical, nursing, or professional healthcare advice or services or the practice of medicine, in any jurisdiction.</em></p>

                </div>
              </div>
            </div>

          </section>

        </main>
      </Layout>
    )
  }
}

export default AccordionListing